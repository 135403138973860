import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image } from 'rebass';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "personal-dashboards-for-confluence"
    }}>{`Personal Dashboards for Confluence`}</h1>
    <br />
    <h3 {...{
      "id": "is-personal-dashboards-really-free"
    }}>{`Is Personal Dashboards really free?`}</h3>
    <div style={{
      width: '80%'
    }}>
Yes. There are no hidden costs, no tracking functionality etc.<br />
Personal Dashboards exists to raise our presence on the Marketplace and we do not plan on monetizing it.
    </div>
    <br />
    <h3 {...{
      "id": "is-personal-dashboards-compatible-with-app-x"
    }}>{`Is Personal Dashboards compatible with App X?`}</h3>
    <div style={{
      width: '80%'
    }}>
Personal Dashboards is not compatible with all Confluence Apps since we use a slightly modified page context.
It should work with most Confluence Macros out of the box, as well as the Jira macros, Charts macros etc.<br />
      <br />
Currently it is not compatible with:<br />
      <br />
      <ul>
    <li>Table of Contents Macro (does not display)</li>
    <li>Task List Macro (not interactive)</li>
    <li>Attachment Previews</li>
      </ul>
    </div>
    <br />
    <h3 {...{
      "id": "can-i-use-it-with-a-theme"
    }}>{`Can I use it with a theme?`}</h3>
    <div style={{
      width: '80%'
    }}>
We have successfully tested Personal Dashboards with Refined Theme and Enterprise Theme for Confluence
    </div>
    <br />
    <h3 {...{
      "id": "is-there-a-cloud-version"
    }}>{`Is there a cloud version?`}</h3>
    <div style={{
      width: '80%'
    }}>
No, it is currently not technically possible
    </div>
    <br />
    <h3 {...{
      "id": "is-data-center-supported"
    }}>{`Is Data Center supported?`}</h3>
    <div style={{
      width: '80%'
    }}>
Yes! We did all the same performance and scalability tests as for our commercial apps.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      